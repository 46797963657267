<template>
  <v-container fluid class="grey lighten-4" style="height: 100%">
    <v-row justify="center">
      <v-col>
        <v-card-title>
          Pobrania dla użytkownika
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-title>
          <v-col cols="12" sm="6" md="4">
            <v-btn-toggle v-model="toggle_one" mandatory rounded dense>
              <v-btn :value="false"> Nierozliczone </v-btn>
              <v-btn :value="true"> Rozliczone </v-btn>
            </v-btn-toggle>
          </v-col>

          <!-- <v-col cols="12" sm="3" md="3">
            <v-spacer></v-spacer>
            <v-btn @click="from = !from">
              <p class="ma-0">Wybierz zakres dat</p>
            </v-btn>
            <v-btn
              @click="
                pickup_date_from = null;
                pickup_date_to = null;
                getDataPickups();
              "
              text
              v-if="pickup_date_from && pickup_date_to"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col> -->
          <v-spacer></v-spacer>

          <v-col cols="12" sm="5" md="5">
            <v-spacer></v-spacer>
            <v-text-field
              v-model="searchUnrealized"
              label="Szukaj"
              single-line
              hide-details
              v-if="!toggle_one"
            ></v-text-field>
            <v-text-field
              v-model="searchPickups"
              label="Szukaj"
              single-line
              hide-details
              v-else
            ></v-text-field>
          </v-col>
        </v-card-title>
        <v-data-table
          :headers="pickupsHeaders"
          :items="pickups"
          class="elevation-1"
          :search="searchPickups"
          :loading="loadingPickups"
          loading-text="Ładowanie... Proszę czekać"
          :options.sync="optionsPickups"
          :server-items-length="totalPickups"
          :footer-props="{
            itemsPerPageOptions: listSize,
            showCurrentPage: true,
            disablePagination: disableFooterPickups,
          }"
          v-show="toggle_one"
        >
          <template v-slot:[`item.p_number`]="{ item }">
            <a :href="'/finanse/pobranie/' + item.pickup_payment_id">
              {{ item.p_number }}
            </a>
          </template>

          <template v-slot:[`item.t_numbers`]="{ item }">
            <a
              v-for="(number, key) in item.t_numbers.split(',')"
              :key="key"
              :href="'/transport/' + item.t_ids.split(',')[key]"
            >
              {{ number }}<span v-if="key < item.t_numbers.split(',').length - 1">,</span>
            </a>
          </template>

          <template v-slot:[`item.price_net`]="{ item }">
            {{ `${item.price_net}\xa0zł` }}
          </template>

          <template v-slot:[`item.printFull`]="{ item }">
            <v-icon @click="downloadFile(item)"> mdi-file </v-icon>
          </template>
        </v-data-table>

        <v-data-table
          :headers="unrealizedHeaders"
          :items="unrealized"
          class="elevation-1"
          :search="searchUnrealized"
          :loading="loadingUnrealized"
          loading-text="Ładowanie... Proszę czekać"
          :options.sync="optionsUnrealized"
          :server-items-length="totalUnrealized"
          :footer-props="{
            itemsPerPageOptions: listSize,
            showCurrentPage: true,
            disablePagination: disableFooterUnrealized,
          }"
          v-show="!toggle_one"
        >
          <template v-slot:[`item.t_number`]="{ item }">
            <a :href="'/transport/' + item.transport_id">
              {{ item.t_number }}
            </a>
          </template>

          <template v-slot:[`item.s_city`]="{ item }">
            {{ item.s_postcode + ' ' + item.s_city }}
          </template>

          <template v-slot:[`item.r_city`]="{ item }">
            {{ item.r_postcode + ' ' + item.r_city }}
          </template>

          <template v-slot:[`item.money_to_collect`]="{ item }">
            {{ `${item.money_to_collect}\xa0zł` }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="from" :dark="false" :light="true" max-width="410px">
      <v-card class="mx-auto" max-width="800" outlined>
        <v-toolbar flat>
          <v-toolbar-title>Data zapłaty od</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-col cols="12" sm="12" md="12">
          <v-date-picker
            v-model="pickup_date_from"
            color="amber darken-1"
            :first-day-of-week="1"
            locale="pl-PL"
            full-width
          ></v-date-picker>
        </v-col>
        <v-row class="ma-0 pa-auto">
          <v-col cols="12" sm="5" md="5">
            <v-btn
              @click="
                from = !from;
                clearDatePicker();
              "
              color="amber accent-3"
              class="grey--text text--darken-4"
              :x-large="true"
              v-tilt
              >Anuluj</v-btn
            >
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="5" md="5">
            <v-btn
              @click="
                from = !from;
                to = !to;
              "
              :disabled="!pickup_date_from"
              color="amber accent-3"
              class="grey--text text--darken-4"
              :x-large="true"
              v-tilt
              >Dalej</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog v-model="to" :dark="false" :light="true" max-width="410px">
      <v-card class="mx-auto" max-width="800" outlined>
        <v-toolbar flat>
          <v-toolbar-title>Data zapłaty do</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-col cols="12" sm="12" md="12">
          <v-date-picker
            v-model="pickup_date_to"
            color="amber darken-1"
            :first-day-of-week="1"
            locale="pl-PL"
            full-width
          ></v-date-picker>
        </v-col>
        <v-row class="ma-0 pa-auto">
          <v-col cols="12" sm="5" md="5">
            <v-btn
              @click="
                to = !to;
                clearDatePicker();
              "
              color="amber accent-3"
              class="grey--text text--darken-4"
              :x-large="true"
              v-tilt
            >
              Anuluj
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="5" md="5">
            <v-btn
              @click="
                getDataPickups();
                to = !to;
              "
              :disabled="!pickup_date_to"
              color="amber accent-3"
              class="grey--text text--darken-4"
              :x-large="true"
              v-tilt
            >
              Zatwierdz
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- snackbar -->
    <InfoSnackBar v-model="info"></InfoSnackBar>

    <FileFormatDialog ref="fileFormatDialog"></FileFormatDialog>
  </v-container>
</template>
<script>
  import store from '@/store';
  import { mdiPackageVariant, mdiPin, mdiContacts } from '@mdi/js';

  import axios from 'axios';
  import moment from 'moment';

  import InfoSnackBar from '../../../components/InfoSnackBar.vue';
  import FileFormatDialog from '../../../components/FileFormatDialog.vue';

  export default {
    components: {
      InfoSnackBar,
      FileFormatDialog,
    },
    props: {
      source: String,
    },
    data: () => ({
      info: null,

      icons: {
        mdiPackageVariant,
        mdiPin,
        mdiContacts,
      },
      pickupsHeaders: [
        {
          text: 'Numer pobrania',
          align: 'left',
          value: 'p_number',
        },
        {
          text: 'Objęte transporty',
          value: 't_numbers',
          sortable: false,
        },
        {
          text: 'Pobranie',
          value: 'price_net',
        },
        // {
        //   text: 'Data utworzenia',
        //   value: 'date_created'
        // },
        {
          text: 'Data zapłaty',
          value: 'date_payed',
          width: 120,
        },
        {
          text: 'Wydruk',
          value: 'printFull',
          sortable: false,
        },
      ],
      unrealizedHeaders: [
        {
          text: 'Numer transportu',
          align: 'left',
          value: 't_number',
        },
        {
          text: 'Numer własny',
          value: 'extension',
        },
        {
          text: 'Dostarczyć',
          value: 'r_city',
          sortable: false,
        },
        {
          text: 'Transport',
          value: 'content',
          sortable: false,
        },
        {
          text: 'Pobranie',
          value: 'money_to_collect',
          sortable: false,
        },
      ],

      pickups: [],
      prevPickups: {
        page: -1,
        pickups: [],
      },
      nextPickups: {
        page: 1,
        pickups: [],
      },
      loadingPickups: false,
      optionsPickups: {
        page: 1,
        itemsPerPage: 20,
        sortBy: ['p_number'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false,
      },
      searchPickups: null,
      totalPickups: 0,
      disableFooterPickups: false,

      from: false,
      to: false,
      pickup_date_from: null,
      pickup_date_to: null,
      listSize: [10, 20, 50, 100],

      unrealized: [],
      prevUnrealized: {
        page: -1,
        payments: [],
      },
      nextUnrealized: {
        page: 1,
        payments: [],
      },
      loadingUnrealized: false,
      optionsUnrealized: {
        page: 1,
        itemsPerPage: 20,
        sortBy: ['t_number'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false,
      },
      searchUnrealized: null,
      totalUnrealized: 0,
      disableFooterUnrealized: false,

      invoices: [],
      media: [],
      search: null,
      isAdmin: store.getters.isAdmin,

      user: null,
      toggle_one: false,
    }),
    watch: {
      searchPickups: function (str) {
        let ref = this;
        setTimeout(function () {
          if (ref.searchPickups === (' ' + str).slice(1)) {
            ref.optionsPickups.page = NaN;
          }
        }, 600);
      },
      searchUnrealized: function (str) {
        let ref = this;
        setTimeout(function () {
          if (ref.searchUnrealized === (' ' + str).slice(1)) {
            ref.optionsUnrealized.page = NaN;
          }
        }, 600);
      },
      'optionsPickups.sortBy': function (next, before) {
        if (next !== before) {
          this.optionsPickups.page = NaN;
        }
      },
      'optionsPickups.itemsPerPage': function (next, before) {
        if (next !== before) {
          this.optionsPickups.page = NaN;
        }
      },
      'optionsPickups.page': async function (next, before) {
        if (isNaN(before)) {
          this.totalPickups = 0;
          this.getDataPickups();
        } else if (isNaN(next)) {
          this.optionsPickups.page = 1;
        } else if (next === before + 1) {
          this.disableFooterPickups = true;
          this.prevPickups.pickups = this.pickups;
          this.prevPickups.page = before;

          this.pickups = this.nextPickups.pickups;

          this.nextPickups.page = next + 1;
          let newNext = await this.getPickups(next + 1);
          this.nextPickups.pickups = newNext.payments;
          this.disableFooterPickups = false;
        } else if (next === before - 1) {
          this.disableFooterPickups = true;
          this.nextPickups.pickups = this.pickups;
          this.nextPickups.page = before;

          this.pickups = this.prevPickups.pickups;

          this.prevPickups.page = next - 1;
          let newPrev = await this.getPickups(next - 1);
          this.prevPickups.pickups = newPrev.payments;
          this.disableFooterPickups = false;
        }
      },
      'optionsUnrealized.sortBy': function (next, before) {
        if (next !== before) {
          this.optionsUnrealized.page = NaN;
        }
      },
      'optionsUnrealized.itemsPerPage': function (next, before) {
        if (next !== before) {
          this.optionsUnrealized.page = NaN;
        }
      },
      'optionsUnrealized.page': async function (next, before) {
        if (isNaN(before)) {
          this.totalUnrealized = 0;
          this.getDataTransports();
        } else if (isNaN(next)) {
          this.optionsUnrealized.page = 1;
        } else if (next === before + 1) {
          this.disableFooterUnrealized = true;
          this.prevUnrealized.payments = this.unrealized;
          this.prevUnrealized.page = before;

          this.unrealized = this.nextUnrealized.payments;

          this.nextUnrealized.page = next + 1;
          let newNext = await this.getTransports(next + 1);
          this.nextUnrealized.payments = newNext.transports;
          this.disableFooterUnrealized = false;
        } else if (next === before - 1) {
          this.disableFooterUnrealized = true;
          this.nextUnrealized.payments = this.unrealized;
          this.nextUnrealized.page = before;

          this.unrealized = this.prevUnrealized.payments;

          this.prevUnrealized.page = next - 1;
          let newPrev = await this.getTransports(next - 1);
          this.prevUnrealized.payments = newPrev.transports;
          this.disableFooterUnrealized = false;
        }
      },
      media: function (val) {
        val.forEach(element => {
          if (!isNaN(parseInt(element))) {
            this.getMedia();
          }
        });
        return;
      },
    },
    methods: {
      clearDatePicker() {
        if (!this.pickup_date_from || !this.pickup_date_to) {
          this.pickup_date_from = null;
          this.pickup_date_to = null;
        }
      },
      getPickups(n) {
        if (n < 1) {
          return {
            payments: [],
            total: 0,
          };
        }
        // eslint-disable-next-line no-unused-vars
        const { sortBy, sortDesc, page, itemsPerPage } = this.optionsPickups;
        let from = (n - 1) * itemsPerPage;
        let to = n * itemsPerPage;

        let search;
        if (this.searchPickups) {
          search = {
            p_number: this.searchPickups,
            'concat(name, " ", surname)': this.searchPickups,
            'concat(surname, " ", name)': this.searchPickups,
            //  name: this.searchPickups,
            //  surname: this.searchPickups,
            union: {
              t_numbers: this.searchPickups,
            },
          };
        } else {
          search = null;
        }

        return axios({
          url: this.$store.state.url + '/api/payment/pickup/search',
          data: {
            token: this.$store.state.token,
            type: 'join',
            query: true,
            sortBy: sortBy,
            sortDesc: sortDesc,
            from: from,
            to: to,
            search: search,
            date_from: this.pickup_date_from,
            date_to: this.pickup_date_to,
            transport: true,
            user_id: this.user,
          },
          method: 'POST',
        })
          .then(resp => {
            if (resp.data.status === 200) {
              for (let [, t] of resp.data.payments.entries()) {
                if (t.date_created)
                  t.date_created = moment(new Date(t.date_created)).format('DD-MM-YYYY');
                if (t.date_payed) t.date_payed = moment(new Date(t.date_payed)).format('DD-MM-YYYY');
              }
              return resp.data;
            } else {
              return {
                payments: [],
                total: 0,
              };
            }
          })
          .catch();
      },
      async getDataPickups() {
        this.disableFooterPickups = true;
        this.loadingPickups = true;
        let newData = await this.getPickups(1);
        this.pickups = newData.payments;
        this.totalPickups = newData.total;
        this.loadingPickups = false;

        this.nextPickups.page = 2;
        let newNext = await this.getPickups(2);
        this.nextPickups.pickups = newNext.payments;

        this.prevPickups.page = 0;
        let newPrev = await this.getPickups(0);
        this.prevPickups.pickups = newPrev.payments;
        this.disableFooterPickups = false;
      },
      getTransports(n) {
        if (n < 1) {
          return {
            transports: [],
            total: 0,
          };
        }

        // eslint-disable-next-line no-unused-vars
        const { sortBy, sortDesc, page, itemsPerPage } = this.optionsUnrealized;
        let from = (n - 1) * itemsPerPage;
        let to = n * itemsPerPage;

        let search;
        if (this.searchUnrealized) {
          search = {
            t_number: this.searchUnrealized,
            client: this.searchUnrealized,
            postcodes: this.searchUnrealized,
            cities: this.searchUnrealized,
          };
        } else {
          search = null;
        }

        let date = new Date(this.pickup_date_to);
        date.setDate(date.getDate() + 1);
        let month_to = '' + (date.getMonth() + 1);
        let day_to = '' + date.getDate();
        let year_to = date.getFullYear();

        if (month_to.length < 2) month_to = '0' + month_to;
        if (day_to.length < 2) day_to = '0' + day_to;

        return axios({
          url: this.$store.state.url + '/api/transport/search',
          data: {
            token: this.$store.state.token,
            sortBy: sortBy,
            sortDesc: sortDesc,
            from: from,
            to: to,
            search: search,
            by_user: {
              user_id: this.user,
              payed_in_advance: 1,
              pickup_payment_id: {
                value: null,
                operator: 'IS',
              },
            },
            date_from: this.pickup_date_from,
            date_to: this.pickup_date_to ? [year_to, month_to, day_to].join('-') : null,
          },
          method: 'POST',
        })
          .then(resp => {
            if (resp.data.status === 200) {
              // for( let [,t] of resp.data.transports.entries() ) {
              //   // let d = new Date(Date.parse(t.date_created)) ;
              //   // t.date_created = d.toLocaleString('pl-PL');

              //   if(t.date_expected) {
              //     let date = new Date(t.date_expected);
              //     let year = date.getFullYear();
              //     let month = date.getMonth()+1;
              //     let dt = date.getDate();

              //     if (dt < 10) {
              //       dt = '0' + dt;
              //     }
              //     if (month < 10) {
              //       month = '0' + month;
              //     }
              //     t.date_expected = dt + '.' + month + '.' + year;
              //   }

              //   if(t.pickup_date) {
              //     let date = new Date(t.pickup_date);
              //     let year = date.getFullYear();
              //     let month = date.getMonth()+1;
              //     let dt = date.getDate();

              //     if (dt < 10) {
              //       dt = '0' + dt;
              //     }
              //     if (month < 10) {
              //       month = '0' + month;
              //     }
              //     t.pickup_date = dt + '.' + month + '.' + year;
              //   }
              // }

              return resp.data;
            } else {
              return {
                transports: [],
                total: 0,
              };
            }
          })
          .catch();
      },
      async getDataTransports() {
        this.disableFooterUnrealized = true;
        this.loadingUnrealized = true;
        let newData = await this.getTransports(1);
        this.unrealized = newData.transports;
        this.totalUnrealized = newData.total;
        this.loadingUnrealized = false;

        this.nextUnrealized.page = 2;
        let newNext = await this.getTransports(2);
        this.nextUnrealized.payments = newNext.transports;

        this.prevUnrealized.page = 0;
        let newPrev = await this.getTransports(0);
        this.prevUnrealized.payments = newPrev.transports;
        this.disableFooterUnrealized = false;
      },
      downloadFile: async function (item) {
        const fileType = await this.$refs.fileFormatDialog.open();
        if (!fileType || typeof fileType !== 'string') return;

        switch (fileType.toLowerCase()) {
          case 'pdf':
            this.downloadPDF(item, 'pickup', true);
            break;
          case 'csv':
            this.downloadCSV(item);
            break;
        }
      },
      downloadCSV: function (item) {
        axios({
          url: this.$store.state.url + '/api/payment/pickup/get_csv',
          data: {
            token: this.$store.state.token,
            id: item.pickup_payment_id,
          },
          method: 'POST',
          responseType: 'blob',
        })
          .then(res => {
            if (res.status === 200) {
              const fileURL = window.URL.createObjectURL(new Blob([res.data]));
              const fileLink = document.createElement('a');

              fileLink.href = fileURL;
              fileLink.setAttribute(
                'download',
                res.headers['content-disposition'].split('filename=')[1].split(';')[0].slice(1, -1) ||
                  `pickup_${item.p_number}.csv`
              );
              document.body.appendChild(fileLink);

              fileLink.click();
              fileLink.parentNode.removeChild(fileLink);
            } else
              this.info = {
                message: 'Akcja nie udała się',
                status: 'error',
                timeout: 2000,
              };
          })
          .catch(err => {
            if (err.response)
              this.info = {
                message: err.response.data.message || 'Akcja nie udała się',
                status: 'error',
                timeout: 2000,
              };
            else
              this.info = {
                message: 'Akcja nie udała się',
                status: 'error',
                timeout: 2000,
              };
          });
      },
      downloadPDF: function (item, type, full = false) {
        let url_base = this.$store.state.url + '/api';
        if ('pickup' === type) {
          url_base += '/payment/pickup/get_pdf';
        } else {
          url_base += '/payment/get_pdf';
        }
        axios({
          url: url_base,
          data: {
            token: this.$store.state.token,
            id: 'pickup' === type ? item.pickup_payment_id : item.payment_id,
            full: full,
          },
          method: 'POST',
          responseType: 'blob',
        })
          .then(resp => {
            if (resp.status === 200) {
              var fileURL = window.URL.createObjectURL(new Blob([resp.data]));
              var fileLink = document.createElement('a');

              fileLink.href = fileURL;
              fileLink.setAttribute(
                'download',
                `${'pickup' === type ? 'pickup' : 'payment'}_${item.p_number}.pdf`
              );
              document.body.appendChild(fileLink);

              fileLink.click();
              fileLink.parentNode.removeChild(fileLink);
            } else {
              if (resp.data.message) alert(resp.data.message);
              else alert('Niepowiodło się');
            }
          })
          .catch(() => {
            alert('Niepowiodło się');
          });
      },
    },
    created() {
      this.user = this.$route.params.id ? decodeURIComponent(this.$route.params.id) : null;
      this.searchPickups = this.$route.query.search
        ? decodeURIComponent(this.$route.query.search)
        : null;
      this.getDataPickups();
      this.getDataTransports();
    },
  };
</script>
